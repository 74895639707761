import { useEffect, useState } from "react";
import { fetchSinToken } from "../../helpers/fetch";
import { useLocation } from "react-router-dom";
import CardModulo from "./components/CardModulo";

type CursoState = {
  cid: String;
  sigla: String;
  nombre: String;
  descripcion: String;
  publico: Boolean;
  activo: Boolean;
};

const Curso = () => {
  let location = useLocation();

  const [curso, setCurso] = useState({
    cid: "",
    sigla: "",
    nombre: "",
    descripcion: "",
    publico: true,
    activo: true,
  });

  const [modulos, setModulos] = useState([
    { cid: "", mid: "", modulo: 0, nombre: "" },
  ]);

  const [bloques, setBloques] = useState([
    { cid: "", mid: "", bid: "", bloque: 0, nombre: "" },
  ]);

  const obtenerCurso = async (cid: String) => {
    try {
      const resp = await fetchSinToken("curso/obtener-publico");
      const body = await resp.json();

      if (body.ok) {
        const { cursos } = body;
        const curso = cursos.find((item: CursoState) => item.cid === cid);
        setCurso(curso);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerModulos = async (cid: string) => {
    try {
      const resp = await fetchSinToken(`modulo/obtener-publico/${cid}`);
      const body = await resp.json();

      if (body.ok) {
        const { modulos } = body;
        setModulos(modulos);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerBloques = async (cid: string) => {
    try {
      const resp = await fetchSinToken(`bloque/obtener-publico/${cid}`);
      const body = await resp.json();

      if (body.ok) {
        const { bloques } = body;
        setBloques(bloques);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { pathname } = location;

    const cid = pathname.split("/", 3)[2];

    if (cid) {
      obtenerCurso(cid);
      obtenerModulos(cid);
      obtenerBloques(cid);
    }
  }, []);

  return (
    <div className="container py-5">
      <h3 style={{ fontWeight: "lighter", marginBottom: "1em" }}>
        {curso.sigla} - {curso.nombre}
      </h3>

      {modulos.map((item) => {
        return <CardModulo key={item.mid} modulo={item} bloques={bloques} />;
      })}
    </div>
  );
};

export default Curso;

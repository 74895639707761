import { useEffect, useState } from "react";
// Reveal
import Reveal from "reveal.js";
import RevealNotes from "reveal.js/plugin/notes/notes.esm.js";
// imagenes
// import logoMATUC from '../../../../img/logo_matuc.png';
// LaTeX
import Latex from "react-latex-next";
import { fetchSinToken } from "../../helpers/fetch";
import { useLocation } from "react-router-dom";

import logoMATUC from "../../img/logo_matuc.png";

import "../../css/reveal.css";
import "../../css/math.css";
import "../../css/diapositivas.css";
import "../../css/globals.css";

type Seccion = {
  cid: String;
  mid: String;
  bid: String;
  sid: String;
  seccion: number;
  nombre: string;
};

type Pagina = {
  pagina: number;
  contenido: string;
};

type Diapositiva = {
  cid: String;
  mid: String;
  bid: String;
  sid: String;
  autor: String;
  diapositivas: Array<Pagina>;
};

const Clase = () => {
  let location = useLocation();

  const [secciones, setSecciones] = useState<Array<Seccion>>([
    { cid: "", mid: "", bid: "", sid: "", seccion: 0, nombre: "" },
  ]);

  const [diapositivas, setDiapositivas] = useState<Array<Diapositiva>>([
    {
      cid: "",
      mid: "",
      bid: "",
      sid: "",
      autor: "",
      diapositivas: [{ pagina: 0, contenido: "" }],
    },
  ]);

  const obtenerDiapositivasBloque = async (bid: String) => {
    try {
      const resp = await fetchSinToken(`diapositiva/obtener-publico/${bid}`);
      const body = await resp.json();

      if (body.ok) {
        const { diapositivas } = body;
        setDiapositivas(diapositivas);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerSeccionesBloque = async (bid: String) => {
    try {
      const resp = await fetchSinToken(`seccion/obtener-publico/${bid}`);
      const body = await resp.json();

      if (body.ok) {
        const { secciones } = body;
        setSecciones(secciones);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { pathname } = location;
    const bid = pathname.split("/", 4)[3];
    if (bid) {
      obtenerSeccionesBloque(bid);
      obtenerDiapositivasBloque(bid);
    }
  }, []);

  useEffect(() => {
    const deck = new Reveal({
      // hash: true,
      controlsLayout: "edges",
      center: false,
      backgroundTransition: "slide",
      transition: "slide",
      margin: 0.05,
      width: "1280",
      height: "800",
      plugins: [RevealNotes],
    });

    deck.initialize({});
    console.log("initialized fool");
  }, []);
  return (
    <div
      className="reveal"
      style={{
        background: "#f7f2d3",
        backgroundColor: "#f7f3de",
        height: "100vh",
      }}
    >
      <div className="slides">
        {/* PORTADA */}
        <section data-transition="slide" className="center" data-notes="Something important">
          <div className="row align-items-center justify-content-center">
            <div className="col-6">
              <h1 style={{ fontSize: "2em", marginBottom: "1em" }}>
                Curso de Matemáticas
              </h1>
              {/* <Latex>{`<h4 style="font-size: 1.3em">${curso.nombre}</h4>`}</Latex> */}
            </div>
            <div className="col-6 text-center">
              <img src={logoMATUC} alt="logo uc" width="500" />
            </div>
          </div>

          {/* <aside className="notes">Shhh, these are your private notes 📝</aside> */}
        </section>

        {secciones.map((seccion) => {
          const diapos = diapositivas.find(
            (diapo) => diapo.sid === seccion.sid
          );

          if (diapos) {
            return diapos.diapositivas.map((pagina) => {
              return (
                <section
                  data-transition="slide"
                  data-align="center"
                  style={{ textAlign: "justify" }}
                  key={`${pagina.pagina}`}
                >
                  <Latex>{pagina.contenido}</Latex>
                </section>
              );
            });
          }
        })}
      </div>
    </div>
  );
};

export default Clase;

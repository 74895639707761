import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Curso = {
  cid: String;
  sigla: String;
  nombre: String;
  descripcion: String;
  publico: Boolean;
  activo: Boolean;
};

type Props = {
  curso: Curso;
};

const CardCurso = ({ curso }: Props) => {

  const navigate = useNavigate();

  const handleCurso = () => {
    navigate(`/curso/${curso.cid}`)
  }
  return (
    <div className="col-lg-6 col-xl-4">
      <div
        className="card mb-5"
        style={{
          width: "250px",
          minHeight: "250px",
          boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.3)",
        }}
      >
        <div
          className="card-header"
          style={{
            background: "transparent",
            borderBottom: "none",
            borderTop: "solid 4px #183e8a",
          }}
        >
          <div className="row justify-content-between m-0">
            <div className="col-auto p-0">
              <div style={{ fontSize: "1.2em", fontWeight: "500" }}>
                {curso.nombre}
              </div>
              <div style={{ color: "GrayText", fontSize: "0.8em" }}>
                {curso.sigla}
              </div>
            </div>
            <div className="col-auto p-0"></div>
          </div>
        </div>
        <div className="card-body" style={{ textAlign: "justify" }}>
          {/* <Latex>{`${curso.descripcion}`}</Latex> */}
        </div>
        <div
          className="card-footer"
          style={{ background: "transparent", borderTop: "none" }}
        >
          <div className="row justify-content-between align-items-center m-0">
            <div className="col-auto p-0">
              <Button
                className="float-end"
                variant="contained"
                color="info"
                onClick={() => handleCurso()}
              >
                Ingresar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCurso;

const baseUrl = import.meta.env.VITE_API_URL;

export const fetchSinToken = (endpoint: string, method = 'GET') => {

    const url = `${baseUrl}/${endpoint}`;

    if (method === 'GET') {
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            }
        });
    }
}

import CardBloque from "./CardBloque";

type Modulo = {
  cid: String;
  mid: String;
  modulo: Number;
  nombre: String;
};

type Bloque = {
  cid: String;
  mid: String;
  bid: String;
  bloque: Number;
  nombre: String;
};

type Props = {
  modulo: Modulo;
  bloques: Array<Bloque>;
};

const CardModulo = ({ modulo, bloques }: Props) => {
  const bloquesModulo = bloques.filter((bloque) => bloque.mid === modulo.mid);
  return (
    <div className="card mb-3">
      <div className="card-header">{modulo.nombre}</div>
      {
        bloquesModulo.map(item => {
          return <CardBloque key={`${item.bid}`} bloque={item}/>
        })
      }
    </div>
  );
};

export default CardModulo;

import { IconButton } from "@mui/material";

type Bloque = {
  cid: String;
  mid: String;
  bid: String;
  bloque: Number;
  nombre: String;
};

type Props = {
  bloque: Bloque;
};

import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useNavigate } from "react-router-dom";

const CardBloque = ({ bloque }: Props) => {

  const navigate = useNavigate();

  const handleDiapositiva = () => {
    navigate(`/curso/clase/${bloque.bid}`)
  }

  return (
    <div className="card-body" style={{ fontWeight: "lighter", borderTop: 'solid 1px #DCDCDC' }}>
      <div className="d-flex flex-row bd-highlight justify-content-between">
        <div className="bd-highlight">{bloque.nombre}</div>
        <IconButton onClick={() => handleDiapositiva()} aria-label="diapositiva" style={{color: "#c29358"}} size="small">
          <MenuBookIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );
};

export default CardBloque;

import { fetchSinToken } from "../../helpers/fetch";
import { useEffect, useState } from "react";
import CardCurso from "./components/CardCurso";

const Inicio = () => {
  
  const [cursos, setCursos] = useState([
    {
      cid: "",
      sigla: "",
      nombre: "",
      descripcion: "",
      publico: true,
      activo: true,
    },
  ]);
  const obtenerCursos = async () => {
    try {
      const resp = await fetchSinToken("curso/obtener-publico");
      const body = await resp.json();

      if (body.ok) {
        const { cursos } = body;
        setCursos(cursos);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    obtenerCursos();
  }, []);

  return (
    <div className="container py-5">
      <div className="row">
        {cursos.map((item) => {
          return <CardCurso key={item.cid} curso={item} />;
        })}
      </div>
    </div>
  );
};

export default Inicio;

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Inicio from "./pages/inicio/Inicio";
import Curso from "./pages/curso/Curso";
import Clase from "./pages/clase/Clase";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/inicio" element={<Inicio />}/> 
        <Route path="/curso/:cid" element={<Curso />}/>
        <Route path="/curso/clase/:bid" element={<Clase />}/>
        <Route path="/*" element={<Navigate to="/inicio" replace />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App